// Imports from Packages
import { Helmet } from 'react-helmet';
import 'animate.css';
import React from 'react';

// Imports from Assets
import '../assets/css/About.css';
import '../assets/css/Cards.css';
import '../assets/css/Buttons.css';
import profile from '../assets/pictures/sunset_person_about.webp';

// Imports from Components
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';



function About() {
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta charSet="utf-8" />
                <title>About</title>
            </Helmet>

            <header>
                <Navbar />
            </header>
            <main>
                <div className='about__background'>
                    <div className='about__main'>
                        <div class="animate__animated animate__zoomIn animate__delay-1s">
                            <img src={profile} alt="profile" />
                        </div>
                        
                            <div className='about__text'>
                                <div class="animate__animated animate__fadeInDown animate__delay-1s">
                                    <h1><strong>About me</strong></h1>
                                </div>
                                <div class="animate__animated animate__fadeInUp animate__delay-1s">
                                <p>Welcome to my personal website, aaronwagner.de! My name is Aaron and I have been programming since the age of 13. I started playing the piano at the age of 8 and have been playing ever since. In addition, I have a passion for playing chess and consider myself a history enthusiast.</p>
                                <p>Programming has always been a fascination of mine. I enjoy the challenge of problem-solving and the satisfaction that comes with creating something new. Playing the piano has been a constant in my life, providing me with a creative outlet and allowing me to express myself through music. Chess, on the other hand, provides me with a mental challenge that I find rewarding. As a history enthusiast, I love learning about the past and how it has shaped our world today. It's fascinating to me how events from centuries ago can still have an impact on our society. </p>
                                <p> Thank you for visiting my website and getting to know a bit about me. I hope you find my content interesting and informative.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default About;