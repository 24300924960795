// Imports from Packages
import { Helmet } from 'react-helmet';
import 'animate.css';
import React from 'react';

// Imports from Assets
import '../assets/css/Gallery.css'
import Flower from '../assets/pictures/iceland_flower_2023_gallery.webp'
import Puffin from '../assets/pictures/iceland_puffin_2023_gallery.webp';
import Road from '../assets/pictures/iceland_road_2023_gallery.webp';
import Cat from '../assets/pictures/iceland_cat_2023_gallery.webp';
import Sheeps from '../assets/pictures/iceland_sheeps_2023_gallery.webp';
import Butterfly from '../assets/pictures/denmark_butterfly_2023_gallery.webp';
import Moon from '../assets/pictures/greece_moon_2022_gallery.webp';
import Basilica from '../assets/pictures/Rom_Basilica_2023.webp';
import Sunset from '../assets/pictures/sunset_greece_background.webp';
import Statue from '../assets/pictures/Acropolis_Museum_2022.webp';

// Imports from Components
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';




function Gallery() {
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta charSet="utf-8" />
                <title>Gallery</title>
            </Helmet>

            <header>
                <Navbar />
            </header>
            <main>
                <div className='gallery__background'>
                    <center>
                        <div class='notice'>
                            <p>Depending on the speed of your internet it may take a while until the images load.</p>
                        </div>
                        <div className='gallery__container'>
                            <div class="animate__animated animate__zoomIn animate__delay-1s">
                                <div className='gallery__normal'>
                                    <img src={Basilica} alt="St. Peter's Basilica" loading="lazy" />
                                    <p className='gallery_copyright'>St. Peter's Basilica, Rom, Italy | ISO 100 | 133mm | 0ev | f2,8 | 1/30s</p>
                                </div>
                                <div className='gallery__normal'>
                                    <img src={Flower} alt="akureyri botanical garden flower" loading="lazy" />
                                    <p className='gallery_copyright'>Akureyri Botanical Garden, Iceland | ISO 100 | 133mm | 0,7ev | f2,8 | 1/80s</p>
                                </div>
                                <div className='gallery__vertical'>
                                    <img src={Cat} alt="akureyri cat" loading="lazy" />
                                    <p className='gallery_copyright'>Akureyri, Iceland | ISO 100 | 600mm | 0,3ev | f3,2 | 1/250s</p>
                                </div>
                                <div className='gallery__normal'>
                                    <img src={Sheeps} alt="sheeps Reykjadalur iceland" loading="lazy" />
                                    <p className='gallery_copyright'>Reykjadalur, Iceland | ISO 100 | 443mm | 0ev | f4 | 1/320s</p>
                                </div>
                                <div className='gallery__normal'>
                                    <img src={Puffin} alt="puffin westmen isles" loading="lazy" />
                                    <p className='gallery_copyright'>Westmen Isles, Iceland | ISO 100 | 600mm | 0,7ev | f3,2 | 1/250s</p>
                                </div>
                                <div className='gallery__normal'>
                                    <img src={Road} alt="road iceland" loading="lazy" />
                                    <p className='gallery_copyright'>Road somewhere in Iceland | ISO 100 | 99mm | 0ev | f4 | 1/800s</p>
                                </div>
                                <div className='gallery__vertical'>
                                    <img src={Butterfly} alt="Botanical Garden Copenhage" loading="lazy" />
                                    <p className='gallery_copyright'>Botanical Garden Copenhagen, Denmark | ISO 100 | 133mm | 0ev | f2,8 | 1/60s</p>
                                </div>
                                <div className='gallery__vertical'>
                                    <img src={Moon} alt="moon saronida" loading="lazy" />
                                    <p className='gallery_copyright'>Saronida, Greece | ISO 100 | 236mm | -1ev | f4 | 1/250s</p>
                                </div>
                                <div className='gallery__normal'>
                                    <img src={Sunset} alt="sunset greece" loading="lazy" />
                                    <p className='gallery_copyright'>Sunset in Greece | ISO 100 | 66mm | -1ev | f4 | 1/640s</p>
                                </div>
                                <div className='gallery__vertical'>
                                    <img src={Statue} alt="Acropolis Museum" loading="lazy" />
                                    <p className='gallery_copyright'>Acropolis Museum, Greece | ISO 20 | 52mm | 0ev | f2 | 1/158s</p>
                                </div>
                            </div>
                        </div>
                    </center>
                </div>
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default Gallery;