// Import from Packages
import React, { useState } from 'react';

// Import from Assets
import '../assets/css/Navbar.css';

// Function for changing the colour of the navbar in mobile mode
function Navbar() {
    const [navbarColor, setNavbarColor] = useState("rgba(255,255,255,0)");

    const handleNavbarColorChange = (event) => {
        const newColor = event.target.checked ? 'rgba(33, 36, 39, 0.815)' : 'rgba(255, 255, 255, 0)';
        setNavbarColor(newColor);
    };

    return (
        <header style={{ backgroundColor: navbarColor }}>
            <h1 style={{ color: `rgb(255,255,255)` }} className="logo"><a href="/">Aaron Wagner</a></h1>
            <input type="checkbox" id="nav-toggle" className="nav-toggle" onChange={handleNavbarColorChange} />
            <nav>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about/">About</a></li>
                    <li><a href="/gallery/">Gallery</a></li>
                    <li><a href="/projects/">Projects</a></li>
                </ul>
            </nav>
            <label htmlFor="nav-toggle" className="nav-toggle-label">
                <span></span>
            </label>
        </header>
    );
}

export default Navbar;
