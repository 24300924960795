// Imports from Packages
// import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'animate.css';
import React from 'react';

// Imports from Assets
import '../assets/css/Home.css';
import '../assets/css/Cards.css';
import '../assets/css/Buttons.css';

// Imports from Components
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

// Imports from Data
// import quotes from '../../data/quotes_en.json';

// // Function to get a random quote and author
// function getRandomQuote() {
//     const randomIndex = Math.floor(Math.random() * quotes.length);
//     const randomQuote = quotes[randomIndex].quote;
//     const randomAuthor = quotes[randomIndex].author;
//     return { quote: randomQuote, author: randomAuthor };
// }


function Home() {
    // // State to hold the current quote and author
    // const [quoteData, setQuoteData] = useState({ quote: '', author: '' });

    // // Run the effect hook only once when the component mounts
    // useEffect(() => {
    //     // Get a random quote and author
    //     const { quote, author } = getRandomQuote();

    //     // Update the state with the random quote and author
    //     setQuoteData({ quote, author });
    // }, []);

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta charSet="utf-8" />
                <title>Home</title>
            </Helmet>

            <header>
                <Navbar />
            </header>
            <main>
                <div className="home__background">
                    {/* <div className="home__text">
                    <p id="quote">{quoteData.quote}</p>
                    <p id="author">- {quoteData.author}</p>
                </div> */}
                    <center>
                        <div class="animate__animated animate__fadeInDown animate__delay-1s">
                            <p className='home__title'>Hi there, I'm Aaron.</p>
                        </div>
                        <div class="animate__animated animate__fadeInUp animate__delay-1s">
                            <p className='home__subtitle'>Welcome to my website.</p>
                        </div>
                    </center>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default Home;



