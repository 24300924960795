import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

// import routes
import Home from './pages/home.js';
import About from './pages/about.js';
import Gallery from './pages/gallery.js';
import Projects from "./pages/projects.js";
import Impressum from "./pages/impressum.js";
import Login from './pages/login.js';

function App() {
  return (
    <Router> 
      <Routes>
        <Route path="/"  exact element={<Home />} />
        <Route path="/about"  exact element={<About />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/projects" exact element={<Projects />}/>
        <Route path="/impressum" exact element={<Impressum/>}/>
        <Route path="/login" exact element={<Login />}/>
      </Routes>
    </Router>
  );
}

export default App;
