// Imports from Packages
import { Helmet } from 'react-helmet';
import 'animate.css';
import React from 'react';

// Imports from Assets
import '../assets/css/Projects.css';

// Imports from Components
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';




function Projects() {
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta charSet="utf-8" />
                <title>Projects</title>
            </Helmet>

            <header>
                <Navbar />
            </header>
            <main>
                <div className='projects__container'>
                    <div className='projects__background'>
                        {/*  Source: https://codepen.io/fbuireu/pen/XBGZdd Ferran Buireu  */}
                        <section class="advice">
                            <div class="animate__animated animate__fadeInDown animate__delay-1s">
                                <h1 class="advice__title">Site under construction or maintenance </h1>
                            </div>
                            <div class="animate__animated animate__fadeInUp animate__delay-1s">
                                <p class="advice__description">{'<'}building{'/>'} not finished yet</p>
                            </div>
                        </section>
                        <section class="city-stuff">
                            <ul class="skyscrappers__list">
                                <li class="skyscrapper__item skyscrapper-1"></li>
                                <li class="skyscrapper__item skyscrapper-2"></li>
                                <li class="skyscrapper__item skyscrapper-3"></li>
                                <li class="skyscrapper__item skyscrapper-4"></li>
                                <li class="skyscrapper__item skyscrapper-5"></li>
                            </ul>
                            <ul class="tree__container">
                                <li class="tree__list">
                                    <ul class="tree__item tree-1">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-2">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-3">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-4">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-5">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-6">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-7">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                    <ul class="tree__item tree-8">
                                        <li class="tree__trunk"></li>
                                        <li class="tree__leaves"></li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="crane__list crane-1">
                                <li class="crane__item crane-cable crane-cable-1"></li>
                                <li class="crane__item crane-cable crane-cable-2"></li>
                                <li class="crane__item crane-cable crane-cable-3"></li>
                                <li class="crane__item crane-stand"></li>
                                <li class="crane__item crane-weight"></li>
                                <li class="crane__item crane-cabin"></li>
                                <li class="crane__item crane-arm"></li>
                            </ul>
                            <ul class="crane__list crane-2">
                                <li class="crane__item crane-cable crane-cable-1"></li>
                                <li class="crane__item crane-cable crane-cable-2"></li>
                                <li class="crane__item crane-cable crane-cable-3"></li>
                                <li class="crane__item crane-stand"></li>
                                <li class="crane__item crane-weight"></li>
                                <li class="crane__item crane-cabin"></li>
                                <li class="crane__item crane-arm"></li>
                            </ul>
                            <ul class="crane__list crane-3">
                                <li class="crane__item crane-cable crane-cable-1"></li>
                                <li class="crane__item crane-cable crane-cable-2"></li>
                                <li class="crane__item crane-cable crane-cable-3"></li>
                                <li class="crane__item crane-stand"></li>
                                <li class="crane__item crane-weight"></li>
                                <li class="crane__item crane-cabin"></li>
                                <li class="crane__item crane-arm"></li>
                            </ul>
                        </section>
                    </div>
                </div>
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default Projects;