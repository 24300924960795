// Import Stylesheets#
import React from 'react';
import '../assets/css/Footer.css';


function Footer() {
    return (
        <div class="footer-dark">
            <footer>
                <center>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6 col-md-3 item">
                                <h3>Links</h3>
                                <ul>
                                    <li><a href="https://cloud.aaronwagner.de">Cloud</a></li>
                                    <li><a href="https://webmail.aaronwagner.de">Webmail</a></li>
                                    <li><a href='/login/'>Dashboard</a></li>
                                    <li><a href="/impressum/">Impressum</a></li>
                                </ul>
                            </div>
                        </div>
                        <br></br>
                        <p class="copyright"><a href='mailto:webmaster@aaronwagner.de'>webmaster@aaronwagner.de</a></p>
                    </div>
                </center>
            </footer>
        </div>
    );
}

export default Footer;